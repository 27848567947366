import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
//import SearchTaskResult from './views/pages/search/SearchTaskResult';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtLogout = Loadable(lazy(() => import('app/views/sessions/JwtLogout')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

// dashboard page
const Dashboard = Loadable(lazy(() => import('app/views/pages/Dashboard')));

// goals page
const Goals = Loadable(lazy(() => import('app/views/pages/goals/Goals')));

// tasks page
const Tasks = Loadable(lazy(() => import('app/views/pages/tasks/Tasks')));

// dreams page
const Viewdreams = Loadable(lazy(() => import('app/views/pages/dreams/Viewdreams')));

// editdreams page
const Editdream = Loadable(lazy(() => import('app/views/pages/dreams/Editdream')));

const EditTask = Loadable(lazy(() => import('app/views/pages/tasks/Edittask')));

// AddEdit dreams page
const Adddream = Loadable(lazy(() => import('app/views/pages/dreams/Adddream')));

// Add goal page
const Addgoal = Loadable(lazy(() => import('app/views/pages/goals/Addgoal')));

// Add goal page
const Editgoal = Loadable(lazy(() => import('app/views/pages/goals/Editgoal')));

// Verify page
const Verify = Loadable(lazy(() => import('app/views/sessions/Verify')));

// Net-Worth page
const Networth = Loadable(lazy(() => import('app/views/pages/net-worth/Networth')));

// settings page
const Settings = Loadable(lazy(() => import('app/views/pages/settings/Settings')));

// analytics page
const Analytics = Loadable(lazy(() => import('app/views/pages/analytics/Analytics')));

// subscription page
const Subscription = Loadable(lazy(() => import('app/views/pages/subscription/Subscription')));

// catchup page
const Catchup = Loadable(lazy(() => import('app/views/pages/catchup/Catchup')));

// help page
const Help = Loadable(lazy(() => import('app/views/pages/help/Help')));

// Quick links page
const Quicklinks = Loadable(lazy(() => import('app/views/pages/quicklinks/Quicklinks')));

// User Settings page
const Usersettings = Loadable(lazy(() => import('app/views/pages/account/Usersettings')));

// Account-setup page
const AccountSetup = Loadable(lazy(() => import('app/views/sessions/AccountSetup')));

// Impersonate page
const Impersonate = Loadable(lazy(() => import('app/views/sessions/Impersonate')));

// Roadmap page
const Roadmap = Loadable(lazy(() => import('app/views/pages/roadmap/Roadmap')));

// Search Dream Result page
const SearchResult = Loadable(lazy(() => import('app/views/pages/search/SearchDreamResult')));

// SearchTaskResult Result page
const SearchTaskResult = Loadable(lazy(() => import('app/views/pages/search/SearchTaskResult')));

// SearchGoalResult Result page
const SearchGoalResult = Loadable(lazy(() => import('app/views/pages/search/SearchGoalResult')));

// Verify 2FA page
const Verify2fa = Loadable(lazy(() => import('app/views/sessions/Verify2FA')));

// ArchiveTasks page
const ArchiveTasks = Loadable(lazy(() => import('app/views/pages/tasks/ArchiveTasks')));

// Tickrt view page
const TicketView = Loadable(lazy(() => import('app/views/pages/help/ViewDirectTicket')));

// All Activity Logs page
const ActivityLogs = Loadable(lazy(() => import('app/views/pages/settings/ViewActivityLogs')));

// Calendar
const CalendarMain = Loadable(lazy(() => import('app/views/pages/CalendarMain')));

// Document
const Document = Loadable(lazy(() => import('app/views/pages/folders/Document')));

// Add New Document
const AddNewDocument = Loadable(lazy(() => import('app/views/pages/folders/AddNewDocument')));

// DocumentLists
const DocumentLists = Loadable(lazy(() => import('app/views/pages/folders/DocumentLists')));

// Folders
const Folders = Loadable(lazy(() => import('app/views/pages/folders/Folders')));

// Reports
const Reports = Loadable(lazy(() => import('app/views/pages/reports/Reports')));

// Report View
const ReportView = Loadable(lazy(() => import('app/views/pages/reports/ReportView')));

// CreateReportView
const CreateReportView = Loadable(lazy(() => import('app/views/pages/reports/CreateReportView')));

// FinanceReport View
const FinanceReportView = Loadable(lazy(() => import('app/views/pages/reports/FinanceReportView')));

// HealthReport View
const HealthReportView = Loadable(lazy(() => import('app/views/pages/reports/HealthReportView')));

// UserReportView View
const UserReportView = Loadable(lazy(() => import('app/views/pages/reports/UserReportView')));

// TimeReportView View
const TimeReportView = Loadable(lazy(() => import('app/views/pages/reports/TimeReportView')));

// FinanceReport View
const FamilyReportView = Loadable(lazy(() => import('app/views/pages/reports/FamilyReportView')));


// User Settings page
const AllNotifications = Loadable(
  lazy(() => import('app/views/pages/notifications/AllNotifications'))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: '/dashboard/',
        element: <Dashboard />,
        auth: authRoles.admin
      },

      // goals route
      {
        path: '/goals/',
        element: <Goals />,
        auth: authRoles.admin
      },

      // Add goals route
      {
        path: '/add-goal/',
        element: <Addgoal />,
        auth: authRoles.admin
      },

      // edit goals route
      {
        path: '/editgoal/',
        element: <Editgoal />,
        auth: authRoles.admin
      },

      // tasks route
      {
        path: '/tasks/',
        element: <Tasks />,
        auth: authRoles.admin
      },

      // dreams route
      {
        path: '/dreams/',
        element: <Viewdreams />,
        auth: authRoles.admin
      },

      // Add dreams route
      {
        path: '/adddream/',
        element: <Adddream />,
        auth: authRoles.admin
      },

      // dit dreams route
      {
        path: '/editdream/',
        element: <Editdream />,
        auth: authRoles.admin
      },

      // net-worth route
      {
        path: '/net-worth/',
        element: <Networth />,
        auth: authRoles.editor
      },

      // settings route
      {
        path: '/settings/',
        element: <Settings />,
        auth: authRoles.editor
      },

      // Analytics route
      {
        path: '/analytics/',
        element: <Analytics />,
        auth: authRoles.editor
      },

      // subscription route
      {
        path: '/subscription/',
        element: <Subscription />,
        auth: authRoles.editor
      },

      // catchup route
      {
        path: '/catch-up/',
        element: <Catchup />,
        auth: authRoles.editor
      },

      // help route
      {
        path: '/help/',
        element: <Help />,
        auth: authRoles.editor
      },

      // Quicklinks route
      {
        path: '/quick-links/',
        element: <Quicklinks />,
        auth: authRoles.editor
      },

      // User Settings route
      {
        path: '/user-settings/',
        element: <Usersettings />,
        auth: authRoles.editor
      },

      // Roadmap route
      {
        path: '/roadmap/',
        element: <Roadmap />,
        auth: authRoles.editor
      },

      // SearchTasks route
      {
        path: '/task/:id',
        element: <SearchTaskResult />,
        auth: authRoles.editor
      },

      // SearchGoals route
      {
        path: '/goal/:id',
        element: <SearchGoalResult />,
        auth: authRoles.editor
      },

      // SearchDreams route
      {
        path: '/dream/:id',
        element: <SearchResult />,
        auth: authRoles.editor
      },

      // AllNotifications route
      {
        path: '/notifications/',
        element: <AllNotifications />,
        auth: authRoles.editor
      },

      // Archive Tasks route
      {
        path: '/archive-tasks/',
        element: <ArchiveTasks />,
        auth: authRoles.editor
      },

      // Ticket Direct View route
      {
        path: '/ticket/:id',
        element: <TicketView />,
        auth: authRoles.editor
      },

      // Activity Logs View route
      {
        path: '/activities/',
        element: <ActivityLogs />,
        auth: authRoles.editor
      },

      // Calendar Main View route
      {
        path: '/calendar/',
        element: <CalendarMain />,
        auth: authRoles.editor
      },

      // Document Main View route
      {
        path: '/document/:id',
        element: <Document />,
        auth: authRoles.editor
      },

      // Add New Document Main View route
      {
        path: '/add-document/',
        element: <Document />,
        auth: authRoles.editor
      },

      // Folders Main View route
      {
        path: '/folders/',
        element: <Folders />,
        auth: authRoles.editor
      },

      // Folder Doc List Main View route
      {
        path: '/folder/:id',
        element: <DocumentLists />,
        auth: authRoles.editor
      },

      // Reports Listing route
      {
        path: '/reports/',
        element: <Reports />,
        auth: authRoles.editor
      },

      // Report Main View route
      {
        path: '/report/:id',
        element: <ReportView />,
        auth: authRoles.editor
      },

      // Finance Report Main View route
      {
        path: '/finance-report/:id',
        element: <FinanceReportView />,
        auth: authRoles.editor
      },

      // Health Report Main View route
      {
        path: '/health-report/:id',
        element: <HealthReportView />,
        auth: authRoles.editor
      },
      
      // User Report Main View route
      {
        path: '/user-report/:id',
        element: <UserReportView />,
        auth: authRoles.editor
      },

      // Time Report Main View route
      {
        path: '/time-report/:id',
        element: <TimeReportView />,
        auth: authRoles.editor
      },

      // Family Report Main View route
      {
        path: '/family-report/:id',
        element: <FamilyReportView />,
        auth: authRoles.editor
      },

      // Create Report Main View route
      {
        path: '/create-report/',
        element: <CreateReportView />,
        auth: authRoles.editor
      }
    ]
  },

  // session pages route
  { path: '/404', element: <NotFound /> },
  { path: '/signin', element: <JwtLogin /> },
  { path: '/signup', element: <JwtRegister /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/verify', element: <Verify /> },
  { path: '/setup-account', element: <AccountSetup /> },
  { path: '/impersonate', element: <Impersonate /> },
  { path: '/verify-2fa', element: <Verify2fa /> },

  { path: '/', element: <JwtLogin /> },
  { path: '/logout', element: <JwtLogout /> },
  { path: '*', element: <NotFound /> },
  // Search result route
  {
    path: '/search-result/',
    element: <SearchResult />
  }
];

export default routes;
