import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
// third party style
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import store from 'app/store';
const configuration = {
  auth: {
    clientId: '9b864017-eac9-46ea-9e38-0e53ebc402aa',
    authority: `https://login.microsoftonline.com/common`
  }
};

const pca = new PublicClientApplication(configuration);
const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <GoogleOAuthProvider clientId="491038274153-mgl61cklqg5dnh8ieg84hir7glnetsg7.apps.googleusercontent.com">
          <Provider store={store}>
            <App />
          </Provider>
        </GoogleOAuthProvider>
      </MsalProvider>
    </BrowserRouter>
  </StyledEngineProvider>
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
